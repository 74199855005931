<template>
  <modal
    ref="modal"
    :class-name="[
      'duration-100 flex flex-col gap-4 p-5 max-w-full w-full md:p-10 md:min-h-full',
      templates.selected ? 'md:w-700px' : 'md:w-1000px',
    ]"
  >
    <h4 class="mb-10">Communication Broadcast</h4>
    <div v-if="templates.selected" class="border border-blue-200 p-4 rounded">
      <h6 class="text-sm font-semibold mb-5 text-gray-600">
        Template Selected:
      </h6>
      <div class="flex flex-col gap-4 mb-5">
        <div class="font-bold text-gray-600 text-lg">
          {{ templates.selected.subject | truncate(40) }}
        </div>
        <div>
          {{ templates.selected.body | truncate(80) }}
        </div>
      </div>
      <button
        type="button"
        class="font-bold text-blue-500 text-sm uppercase focus:outline-none"
        @click="select(null)"
      >
        Choose another
      </button>
    </div>
    <!-- Select Template -->
    <div v-else class="border border-blue-200 p-4 rounded">
      <div class="mb-10">
        <search-form
          v-model="query.search"
          placeholder="Search subject"
          @submit="getTemplates"
        />
      </div>
      <div
        class="gap-5 gap-y-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mb-10"
      >
        <template v-if="templates.loading">
          <template v-for="index in 4">
            <skeleton-loader
              :key="index"
              class="opacity-10 rounded"
              height="10rem"
              width="100%"
            />
          </template>
        </template>
        <template v-else v-for="(template, index) in templates.data">
          <div
            :key="index"
            class="border card cursor-pointer flex flex-col gap-4 hover:shadow-card"
            @click="select(template)"
          >
            <div class="font-bold text-gray-600 text-base">
              {{ template.subject | truncate(40) }}
            </div>
            <div class="text-sm">
              {{ template.body | truncate(80) }}
            </div>
          </div>
        </template>
      </div>
      <Pagination
        v-model="query.page"
        :meta="templates.meta"
        :is-loading="templates.loading"
        @change="getTemplates"
      />
    </div>
    <template v-if="templates.selected">
      <!-- Configurations -->
      <div class="border border-blue-200 p-4 rounded">
        <h6 class="text-sm font-semibold mb-5 text-gray-600">
          Broadcast Settings:
        </h6>
        <div class="flex gap-4 flex-wrap">
          <div class="flex gap-2 items-center">
            <checkbox v-model="form.data.to_app.value" id="to_app" />
            <label for="to_app" class="cursor-pointer">Broadcast to app</label>
          </div>
          <div class="flex gap-2 items-center">
            <checkbox v-model="form.data.to_mail.value" id="to_mail" />
            <label for="to_mail" class="cursor-pointer"
              >Broadcast to mail</label
            >
          </div>
          <div class="flex gap-2 items-center">
            <checkbox v-model="form.data.to_sms.value" id="to_sms" />
            <label for="to_sms" class="cursor-pointer">Broadcast to sms</label>
          </div>
          <div class="flex gap-2 items-center">
            <checkbox
              v-model="form.data.show_call_to_action.value"
              id="show_call_to_action"
            />
            <label for="show_call_to_action" class="cursor-pointer">
              Include
              <span
                class="bg-gray-200 font-bold px-2 py-1 rounded-sm text-sm uppercase"
              >
                Call to action
              </span>
            </label>
          </div>
        </div>
      </div>
      <!-- Add More Users -->
      <div class="border border-blue-200 p-4 rounded">
        <h6 class="text-sm font-semibold mb-5 text-gray-600">
          Include Other Users:
        </h6>
        <div v-if="form.data.users.value.length" class="flex flex-wrap gap-4 mb-5">
          <template v-for="(id, index) in form.data.users.value">
            <div
              :key="index"
              class="bg-blue-200 cursor-pointer font-bold px-2 py-1 rounded-sm text-sm uppercase"
              @click="removeUserId(index)"
            >
              {{ id }}
            </div>
          </template>
        </div>
        <form-group
          v-model="id"
          placeholder="Enter User ID e.g 11252"
          type="number"
          @keyup.enter="addUserId"
        />
      </div>
      <!-- Submit -->
      <div class="flex justify-end">
        <button type="submit" class="btn btn-blue btn-md" :disabled="form.loading" @click="submit">
          <sm-loader-white v-if="form.loading" />
          <span v-else>Send Broadcast</span>
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'ComponentName',
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  components: {
    Pagination: () => import('@/components/presentation/Pagination.vue'),
  },
  data() {
    return {
      id: '',
      query: {
        search: '',
        page: 1,
      },
      form: this.$options.basicForm([
        { name: 'users', value: [] },
        { name: 'to_app', value: true },
        { name: 'to_mail', value: false },
        { name: 'to_sms', value: false },
        { name: 'show_call_to_action', value: false },
      ]),
      templates: this.$options.resource([], { meta: {}, selected: null }),
    };
  },
  mounted() {
    this.getTemplates();
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    open() {
      this.$refs.modal.open();
    },
    select(template) {
      this.templates.selected = template;
    },
    addUserId() {
      if (!this.id || this.form.data.users.value.includes(this.id)) return;
      this.form.data.users.value.push(this.id);
      this.id = '';
    },
    removeUserId(index) {
      this.form.data.users.value.splice(index, 1);
    },
    async getTemplates() {
      this.templates.data = [];
      this.templates.selected = null;
      this.templates.loading = true;
      await this.sendRequest(
        'admin.communications.templates.list',
        this.query,
        {
          success: (response) => {
            const { data, ...meta } = response.data.datatable;
            this.templates.data = data;
            this.templates.meta = meta;
          },
          error: (error) => {
            this.$error({ title: error?.response?.data?.message });
          },
        }
      ).finally(() => (this.templates.loading = false));
    },
    async submit() {
      const data = this.getFormData();
      data.users.unshift(this.staff.id);
      data.template_id = this.templates.selected.id;
      this.form.loading = true;
      await this.sendRequest(
        'admin.communications.broadcast',
        {
          data,
          success: () => {
            this.$success({
              title: 'Operation successful',
              body: 'The broadcast has been sent to the selected user(s)',
              button: 'Okay',
            });
            this.close();
            this.form = this.resetForm(this.form);
          },
          error: (error) => {
            this.$error({ title: error?.response?.data?.message });
          },
        }
      ).finally(() => (this.form.loading = false));
    },
  },
};
</script>
